<template>
  <!-- 门店订单发货 -->
  <div class="wrapper">
    <!-- <admin-title title="物流配送 - 门店自提"></admin-title> -->
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="设备串号" prop="serialNo" :rules="rules">
        <el-input
          style="width: 500px"
          v-model="formData.serialNo"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item label="监管锁" prop="lockCode">-->
      <!--        <el-input style="width:500px;" v-model="formData.lockCode" size="small" placeholder="请输入"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="签收证明" prop="receiveImageList" :rules="rules">
        <!-- <FileUpload :isMulti="true" :name.sync="formData.deliveryImage" :url="formData.masterImageUrlList"></FileUpload> -->
        <FileUpload
          :isMulti="true"
          :name.sync="formData.receiveImageList"
        ></FileUpload>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          style="width: 500px"
          v-model="formData.remark"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <div class="payment-content">
        <p class="title">门店结算明细</p>
        <p>
          <span class="label">结算门店：</span>
          <span class="color-red">{{
            storeSettlementInformation.store
              ? storeSettlementInformation.store.storeName
              : "-"
          }}</span>
        </p>
        <p>
          <span class="label">结算方式：</span>
          <span class="color-red">{{
            storeSettlementInformation.settleTypeName
          }}</span>
        </p>
        <p v-if="storeSettlementInformation.settleType == 2">
          <span class="label">收款账户姓名：</span>
          <span class="color-red">{{
            storeSettlementInformation.store
              ? storeSettlementInformation.store.payeeIdName
              : "-"
          }}</span>
        </p>
        <p v-if="storeSettlementInformation.settleType == 2">
          <span class="label">收款支付宝账号：</span>
          <span class="color-red">{{
            storeSettlementInformation.store
              ? storeSettlementInformation.store.payeeAlipayAccount
              : "-"
          }}</span>
        </p>
        <p>
          <span class="label">门店结算金额：</span>
          <span class="color-red f18"
            >{{ storeSettlementInformation.settleAmount || "-"
            }}<span class="f12">元</span></span
          >
        </p>
        <p>
          <span class="label mt20">订单号：</span>
          <span>{{ storeSettlementInformation.orderNo || "-" }}</span>
        </p>
        <p>
          <span class="label">商品信息：</span>
          <span>{{ storeSettlementInformation.goodsTitle || "-" }}</span>
        </p>
        <p>
          <span class="label">商品进货价：</span>
          <span>￥{{ storeSettlementInformation.costAmount || "-" }}</span>
        </p>
        <p>
          <span class="label">客户首付款：</span>
          <span
            >￥{{ storeSettlementInformation.prepayAmount || "-" }} ({{
              storeSettlementInformation.prepayPayeeType == 1
                ? "平台收取"
                : "门店收取"
            }})</span
          >
        </p>
        <p>
          <span class="label">监管锁费用：</span>
          <span
            >￥{{ storeSettlementInformation.lockAmount || "-" }} ({{
              storeSettlementInformation.lockPayeeType == 1
                ? "平台收取"
                : "门店收取"
            }})</span
          >
        </p>
        <p>
          <span class="label">公证费：</span>
          <span
            >￥{{ storeSettlementInformation.notaryAmount || "-" }} ({{
              storeSettlementInformation.notaryPayeeType == 1
                ? "平台收取"
                : "门店收取"
            }})</span
          >
        </p>
        <p>
          <span class="label">订单押金：</span>
          <span
            >￥{{ storeSettlementInformation.depositAmount || "-" }} ({{
              storeSettlementInformation.depositPayeeType == 1
                ? "平台收取"
                : "门店收取"
            }})</span
          >
        </p>
        <p v-if="Number(storeSettlementInformation.rewardAmount) !== 0">
          <span class="label">其他货款：</span>
          <span>￥{{ storeSettlementInformation.rewardAmount }}</span>
        </p>
      </div>
      <el-form-item label="">
        <el-button type="" size="small" @click="closePage">取消</el-button>
        <el-button type="primary" size="small" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { handleDelieryStoreAPI, settlementAmountDetails } from "./api";
import FileUpload from "@/components/file-upload/index-send.vue";

export default {
  name: "DeliveryStore",
  components: { FileUpload },
  data() {
    return {
      storeSettlementInformation: {},
      row: {},
      rules: [{ required: true, message: "该项不能为空", trigger: "blur" }],
      formData: {
        orderNo: this.$route.params.id,
        serialNo: "",
        lockCode: "",
        receiveImageList: [],
        remark: "",
      },
    };
  },

  mounted() {
    this.getSettlementAmountDetails(); //获取门店结算金额明细
  },

  methods: {
    async getSettlementAmountDetails() {
      console.log(111);
      let res = await settlementAmountDetails({
        orderNo: this.$route.params.id,
      });
      this.storeSettlementInformation = res;
      console.log(res);
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          handleDelieryStoreAPI(this.formData).then(() => {
            this.$message.success("自提成功");
            //
            setTimeout(() => {
              this.closePage();
            }, 1000);
          });
        }
      });
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList();
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList();
      }
    },
    closePage() {
      this.emitAwindow();
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
  .payment-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }
    > p {
      line-height: 30px;
    }
    .label {
      font-weight: 700;
      width: 10%;
      display: inline-block;
      text-align: right;
    }
    .color-red {
      color: rgb(151, 3, 3);
    }
  }
}
</style>
